import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MessagingService } from 'src/app/messaging.service';
@Component({
  selector: 'vc-app-notification',
  template: `
    <div class="popup-box-container">
    
    <div class="policyContent">
   
    <h2 class="popupHeading">Notification Permission</h2> 
    <div class="popupDesc ck-content">
      <p [innerHTML]="data.message"></p>
    </div> 
    
    </div>
    <div> 
      <div class="actionButton"> 
        <button class="accept" *ngIf="data.showAllow" (click)="userRequestedNotificationPermission()" cdkFocusInitial>Allow </button>
        <button class="reject" (click)="close()">Close </button>
      </div>
    </div>

   </div>
`,
  styleUrls: ['./app-notification.component.scss']
})
export class AppNotificationComponent implements OnInit {
  isPermissionGranted: boolean;

  constructor(private messagingService: MessagingService, public dialogRef: MatDialogRef<AppNotificationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { message: string, showAllow: boolean }, private dialog: MatDialog) {
  }

  ngOnInit(): void {

  }
 
  async userRequestedNotificationPermission() {
    this.isPermissionGranted = this.messagingService.isPermissionGranted();
    console.log('isPermissionGranted', this.isPermissionGranted);
    if (!this.isPermissionGranted) {
      this.data.message = "Notifications are are being enabled.";
      this.data.showAllow = false;
      await this.messagingService.requestNotificationPermission();
      let checkNotificationStatusObject = this.messagingService.checkNotificationStatus();

      this.dialog.open(AppNotificationComponent, {
        data: {
           message: checkNotificationStatusObject["message"],
          showAllow:checkNotificationStatusObject["showAllow"]
        }, // Optional: Prevent closing the dialog without user action
      });
    } else {
      console.log('Permission already granted');
    }
    this.dialogRef.close();
  }


  close() {
    this.dialogRef.close();
  }
}
